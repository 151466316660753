<template>
  <v-card class="mx-auto">
    <v-card-title>
      <span class="font-weight-light text-capitalize text-truncate">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              color="yellow  darken-3"
              small
              text-color="white"
              class="mr-1"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon left small> mdi-star </v-icon>
              {{ item.soldForPoints }}
            </v-chip>
          </template>
          <span>{{ $t("tooltip.points") }}</span> </v-tooltip
        >{{ item.product?.modelName }}</span
      >
    </v-card-title>
    <v-img
      class="white--text align-end"
      contain
      :height="imageHeight"
      :src="item.product?.pictureUrl"
      @error="item.product.pictureUrl = require('@/assets/picture-loading-failed-1.png')"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
        </v-row>
      </template>
    </v-img>
    <v-divider v-if="showActions"> </v-divider>
    <v-card-subtitle class="px-4 py-2">
      <span class="font-weight-bold">
        <v-icon left> mdi-account </v-icon>{{ item.salesmanUsername }}</span
      >
    </v-card-subtitle>
    <v-card-subtitle class="px-4 py-2" v-if="item.salesCampaign">
      <span class="font-weight-bold">
        <v-icon left> mdi-cellphone-wireless </v-icon>{{ item.salesCampaign.name }}</span
      >
    </v-card-subtitle>
    <v-card-subtitle class="px-4 py-2" v-if="item.shop">
      <span class="font-weight-bold"> <v-icon left>mdi-sale</v-icon>{{ item.shop.name }}</span>
    </v-card-subtitle>
    <v-divider v-if="!isAdmin()"> </v-divider>
    <v-card-actions v-if="!isAdmin()">
      <span class="caption grey--text font-weight-bold">{{ formatDate(item.date) }}</span>
      <v-spacer></v-spacer>
      <v-btn text depressed plain :ripple="false" v-if="item.state == 0">
        <v-icon left color="info">mdi-timelapse</v-icon>
        <span>{{ $t("filters.saleFilter.waitingApproval") }}</span>
      </v-btn>
      <v-btn text depressed plain :ripple="false" v-if="item.state == 1">
        <v-icon left color="success">mdi-check-circle-outline</v-icon>
        <span>{{ $t("filters.saleFilter.approved") }}</span>
      </v-btn>
      <v-btn text depressed plain :ripple="false" v-if="item.state == 2">
        <v-icon left color="error">mdi-cancel</v-icon>
        <span>{{ $t("filters.saleFilter.rejected") }}</span>
      </v-btn>
    </v-card-actions>
    <v-divider v-if="showActions && isAdmin()"> </v-divider>
    <v-card-actions v-if="showActions && isAdmin()">
      <span class="caption grey--text font-weight-bold">{{ formatDate(item.date) }}</span>
      <v-spacer></v-spacer>
      <v-tooltip bottom v-if="item.state == 1">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            text
            depressed
            plain
            :ripple="false"
            v-if="item.state == 1"
          >
            <v-icon left color="success">mdi-check-circle-outline</v-icon>
            <span>{{ $t("filters.saleFilter.approved") }}</span>
          </v-btn>
        </template>
        <span>{{ $t("filters.saleFilter.approved") }}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="item.state == 2">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            text
            depressed
            plain
            :ripple="false"
            v-if="item.state == 2"
          >
            <v-icon left color="error">mdi-cancel</v-icon>
            <span>{{ $t("filters.saleFilter.rejected") }}</span>
          </v-btn>
        </template>
        <span>{{ $t("filters.saleFilter.rejected") }}</span>
      </v-tooltip>
      <div class="d-flex">
        <v-tooltip bottom v-if="item.state == 0">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              depressed
              plain
              small
              color="error"
              @click="rejectSale(item.id)"
            >
              <v-icon left>mdi-cancel</v-icon>
              {{ $t("app.cancel") }}
            </v-btn>
          </template>
          <span>{{ $t("tooltip.cancel") }}</span>
        </v-tooltip>
        <v-tooltip bottom v-if="item.state == 0">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              depressed
              small
              color="success"
              @click="approveSale(item.id)"
            >
              <v-icon left>mdi-check-circle-outline</v-icon>
              {{ $t("app.approve") }}
            </v-btn>
          </template>
          <span>{{ $t("app.approve") }}</span>
        </v-tooltip>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      confirmDeleteDialog: false
    };
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    imageHeight: {
      type: String,
      required: false,
      default: "200px"
    },
    limitText: {
      type: String,
      required: false,
      default: "40"
    },
    showActions: {
      type: Boolean,
      required: false,
      default: false
    },
    namespace: {
      type: String,
      required: false,
      default: ""
    }
  },
  methods: {
    async approveSale(id) {
      await this.$store.dispatch(`${this.namespace}/approve`, id);
      Event.$emit("reload-data");
    },
    async rejectSale(id) {
      await this.$store.dispatch(`${this.namespace}/reject`, id);
      Event.$emit("reload-data");
    }
  }
};
</script>

<style scoped>
.break-all-words >>> p {
  margin-bottom: 0 !important;
}
.break-all-words {
  word-break: break-all;
}
</style>
